import { start } from 'single-spa';

import 'babel-polyfill';

import { CommonAppLoader, ProtocolsAppLoader } from './loaders';
import { redirectTo } from './shared/navigation';

CommonAppLoader();
ProtocolsAppLoader();

redirectTo('/', 'protocols');

start();
