import { mountRootParcel, registerApplication } from 'single-spa';
import { Events } from '../shared/events';

const Loader = ({ appEnv, customProps }) => ({
  customProps: {
    events: Events,
    ...customProps,
  },
  appLoading: () => (appEnv ? window.System.import(appEnv) : Promise.reject()),
});

const RegisterApplication = (appName, appEnv, activityWhen, customProps) => {
  const loader = Loader({ appEnv, customProps });
  registerApplication(
    appName,
    loader.appLoading(),
    activityWhen,
    loader.customProps,
  );
};

const MountRootParcel = (
  { appName, appEnv, activityWhen, customProps },
  domElementId,
) => {
  const loader = Loader({ appEnv, customProps });

  const fetchDomElement = id => {
    if (!id || document.getElementById(id)) {
      return document.getElementById(id);
    } else {
      const domElement = document.createElement('div');
      domElement.setAttribute('id', id);
      document.body.appendChild(domElement);
      return domElement;
    }
  };

  mountRootParcel(loader.appLoading, {
    ...loader.customProps,
    registerApplication,
    domElement: fetchDomElement(domElementId),
  });
};

export { RegisterApplication, MountRootParcel };
