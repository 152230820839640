import { envVars } from '../envVars';
import { MountRootParcel } from './Loader';

const config = {
  appName: 'protocols',
  appEnv: envVars.REACT_APP_PROTOCOLS,
  activityWhen: () => true,
  customProps: {},
};

const ProtocolsAppLoader = () => MountRootParcel(config, document.body);

export { ProtocolsAppLoader };
