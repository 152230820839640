const ListBox = (items = []) => ({
  fold: () => items || [],
  map: f => f(ListBox(items)),
  remove: i => items.splice(i, 1),
  fetch: (f, c) => c(items.find(f)),
  add: i => {
    items.push(i);
    return i;
  },
});

const Events = (function(listeners, queue) {
  const fetch = ({ type, payload, persist }, f) =>
    listeners.fetch(
      ({ type: _t }) => _t === type,
      l => (l ? f(l.event) : queue.add({ type, payload, persist })),
    );
  const retry = l => {
    l.id = listeners.fold().length;
    return queue.fold().map(({ type, payload, persist }, i) => {
      fetch({ type, payload }, e => {
        e.next(payload);
        !persist && queue.remove(i);
      });
    });
  };
  const removeListenerBy = f =>
    (listeners = ListBox(listeners.fold().filter(f)));
  return {
    addListener: l => retry(listeners.add(l)),
    removeListener: _id => removeListenerBy(({ id }) => id !== _id),
    removeListenerByType: t => removeListenerBy(({ type }) => type !== t),
    emit: (type, payload, persist) =>
      fetch({ type, payload, persist }, e => e.next(payload)),
    queue: () => queue.fold(),
    destroy: () => {
      listeners = ListBox();
      queue = ListBox();
    },
  };
})(ListBox(), ListBox());

export { Events };
